@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "MiddleEarth";
    src: url("./fonts/middleearth.ttf") format("truetype");
  }
  @font-face {
    font-family: "Philosopher";
    src: url("./fonts/Philosopher-Regular.ttf") format("truetype");
  }
}
